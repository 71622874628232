import React from 'react';

export type TShowVideoContext = {
  showVideo?: boolean;
  setShowVideo?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ShowVideoContext = React.createContext<TShowVideoContext>({});

interface ShowVideoContextProviderProps {
  children: React.ReactNode;
}

export function ShowVideoContextProvider({
  children,
}: ShowVideoContextProviderProps) {
  const [showVideo, setShowVideo] = React.useState<boolean>(true);
  const [videoPlayed, setVideoPlayed] = React.useState<boolean>(false); // Add a state value to keep track of whether the video has already been played
  const vidRef = React.useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const _videoPlayed = localStorage.getItem('videoPlayed');
    const lastPlayedDate = new Date(_videoPlayed);
    const currentDate = new Date();
    const diff = currentDate.getTime() - lastPlayedDate.getTime();
    const diffMins = diff / (1000 * 60);
    if (diffMins > 15) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
    if (window.innerWidth < 768) {
      setShowVideo(false);
    }
    setLoading(false);
  }, []);

  const context = React.useMemo(
    () => ({ showVideo: !videoPlayed, setShowVideo }),
    [videoPlayed, setShowVideo]
  );

  const videoEnded = () => {
    setShowVideo(false);
    setVideoPlayed(true);
    localStorage.setItem('videoPlayed', new Date().toISOString());
  };

  const setPlayBack = () => {
    if (vidRef.current) {
      vidRef.current.playbackRate = 2;
    }
  };

  const handlePlayClick = () => {
    if (vidRef.current) {
      vidRef.current.play();
    }
  };

  return (
    <ShowVideoContext.Provider value={context}>
      {showVideo &&
        !loading && ( // Use the videoPlayed state value to conditionally render the video player
          <div className=" relative w-full h-screen flex justify-center items-center bg-brown-200">
            <video
              onEnded={videoEnded}
              ref={vidRef}
              onPlay={setPlayBack}
              autoPlay
              muted
              className="w-full h-full object-fill md:object-cover"
            >
              {/* <source src="/media/video/mogos.mp4" type="video/mp4" /> */}
              <source src="/media/video/mogos2.mp4" type="video/mp4" />
            </video>
            <button
              className="md:hidden absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-dark-100 rounded-full"
              onClick={handlePlayClick}
              type="button"
            >
              <svg
                className="w-12 h-12"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#CFC392"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        )}
      {!showVideo && !loading && children}
    </ShowVideoContext.Provider>
  );
}
