import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import Script from 'next/script';
import Link from 'next/link';
import {
  ShowVideoContextProvider,
  ThemeContextProvider,
  ToastContextProvider,
  UserContextProvider,
} from '../context';
import { useApollo } from '../hooks';
import '../style.css';
import { Toaster } from '../components/ui/toaster';
import { ThemeProvider } from '../components/theme-provider';
import Chatbot from '../components/chatbot';

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: AppProps<any>) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // On page load or when changing themes, best to add inline in `head` to avoid FOUC
      if (
        localStorage.theme === 'light' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: light)').matches)
      ) {
        document.documentElement.classList.add('light');
      } else {
        document.documentElement.classList.remove('light');
      }

      // Whenever the user explicitly chooses light mode
      localStorage.theme = 'light';

      // Whenever the user explicitly chooses light mode
      localStorage.theme = 'light';

      // Whenever the user explicitly chooses to respect the OS preference
      localStorage.removeItem('theme');
    }
  }, []);

  return (
    <SessionProvider session={pageProps.session} refetchInterval={0}>
      <ApolloProvider client={apolloClient}>
        <>
          <Script
            id="A3bK9zX1pR7uV5wE2tO8dY4mQ0"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", 'iwkbeqy7ny');`,
            }}
          />
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <title>MOGOS Group</title>
          </Head>
          {/* <ShowVideoContextProvider> */}
          {/* <ThemeContextProvider> */}
          <ToastContextProvider>
            <UserContextProvider>
              {/* <ThemeProvider
                    attribute="class"
                    defaultTheme="light"
                    enableSystem
                  > */}
              <Component {...pageProps} err={err} />
              <Link
                className="hidden fixed bottom-6 z-100 right-6 w-16 h-16 bg-success-500 rounded-full hover:scale-110 transition-all ease-in-out duration-300 flex justify-center items-center"
                href="https://api.whatsapp.com/send?phone=584241856592&text=Hola%20Mogos%20Group%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.375549 12.0984L0.855549 10.6684C1.01555 10.2084 1.14555 9.73836 1.32555 9.28836C1.37469 9.19242 1.40032 9.08616 1.40032 8.97836C1.40032 8.87056 1.37469 8.76431 1.32555 8.66836C0.756992 7.6187 0.524959 6.4196 0.660909 5.23361C0.796859 4.04763 1.29422 2.93214 2.08555 2.03836C2.66005 1.38347 3.37702 0.868982 4.18137 0.534411C4.98573 0.199839 5.85608 0.0540914 6.72555 0.108362C8.04339 0.15185 9.30381 0.65814 10.2855 1.53836C11.2139 2.34641 11.8617 3.42841 12.1355 4.62836C12.3642 5.59873 12.3366 6.61187 12.0555 7.56836C11.7119 8.77032 10.9806 9.825 9.97555 10.5684C9.3101 11.0866 8.53827 11.4513 7.71535 11.6363C6.89243 11.8213 6.03878 11.822 5.21555 11.6384C4.69475 11.5336 4.19253 11.3516 3.72555 11.0984C3.68958 11.0814 3.65031 11.0726 3.61055 11.0726C3.57079 11.0726 3.53152 11.0814 3.49555 11.0984L0.815549 11.9384L0.375549 12.0984ZM1.89555 10.5884H1.98555L3.66555 10.0584C3.72391 10.0386 3.78718 10.0386 3.84555 10.0584C4.08555 10.1784 4.31555 10.3284 4.56555 10.4284C5.56186 10.866 6.68009 10.9403 7.72555 10.6384C8.34046 10.4741 8.91639 10.1887 9.41953 9.79891C9.92267 9.40911 10.3429 8.92275 10.6555 8.36836C11.276 7.28831 11.4591 6.01157 11.167 4.80069C10.875 3.58981 10.1301 2.53686 9.08555 1.85836C8.13977 1.24478 7.01009 0.979089 5.88996 1.10679C4.76984 1.23449 3.72895 1.74764 2.94555 2.55836C2.30428 3.21319 1.86662 4.0399 1.68555 4.93836C1.54322 5.55434 1.52745 6.19279 1.63918 6.81505C1.75091 7.4373 1.98782 8.03038 2.33555 8.55836C2.392 8.65191 2.42184 8.7591 2.42184 8.86836C2.42184 8.97762 2.392 9.08481 2.33555 9.17836C2.20555 9.61836 2.06555 10.0984 1.89555 10.5884Z"
                    fill="white"
                  />
                  <path
                    d="M9.43552 7.6283C9.42943 7.84118 9.3633 8.04801 9.24475 8.22494C9.12621 8.40187 8.96008 8.54169 8.76552 8.6283C8.56356 8.73867 8.34004 8.80387 8.11041 8.81941C7.88078 8.83495 7.65051 8.80045 7.43552 8.7183C7.02379 8.56897 6.61993 8.39875 6.22552 8.2083C5.716 7.93838 5.2615 7.57546 4.88552 7.1383C4.45895 6.66913 4.09277 6.14841 3.79552 5.5883C3.57256 5.20013 3.46827 4.75512 3.49552 4.3083C3.51608 4.07758 3.58558 3.8539 3.69939 3.65215C3.8132 3.4504 3.96869 3.27523 4.15552 3.1383C4.24168 3.07998 4.34173 3.04548 4.44552 3.0383C4.57198 3.02809 4.69906 3.02809 4.82552 3.0383C4.87177 3.04415 4.9153 3.06335 4.95082 3.09354C4.98634 3.12372 5.01229 3.1636 5.02552 3.2083C5.12552 3.4383 5.20552 3.6683 5.29552 3.9083C5.38552 4.1483 5.40552 4.2283 5.47552 4.3783C5.53706 4.46095 5.56687 4.56295 5.55953 4.66573C5.55219 4.76851 5.50818 4.86524 5.43552 4.9383C5.33925 5.06406 5.23217 5.18117 5.11552 5.2883C5.07285 5.32193 5.04413 5.37017 5.0349 5.42371C5.02566 5.47725 5.03657 5.53232 5.06552 5.5783C5.33626 6.07 5.69564 6.50736 6.12552 6.8683C6.43367 7.09942 6.76964 7.29092 7.12552 7.4383C7.16239 7.47203 7.21055 7.49074 7.26052 7.49074C7.31049 7.49074 7.35865 7.47203 7.39552 7.4383L7.89552 6.8683C8.00552 6.7483 8.05552 6.7183 8.19552 6.7883L9.42552 7.4283C9.44216 7.45873 9.4517 7.49252 9.45343 7.52715C9.45516 7.56179 9.44904 7.59637 9.43552 7.6283Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <Chatbot />
              {/* </ThemeProvider> */}
              <Toaster />
            </UserContextProvider>
          </ToastContextProvider>
          {/* </ThemeContextProvider> */}
          {/* </ShowVideoContextProvider> */}
        </>
      </ApolloProvider>
    </SessionProvider>
  );
}

export default MyApp;
