import { useEffect, useRef, useState } from 'react';
import { Button } from './ui';
import { Badge } from './ui/badge';
import { Card } from './ui/card';

export default function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);

  // Open the chatbot automatically after 10 seconds only if it hasn't been opened before
  useEffect(() => {
    const hasOpened = localStorage.getItem('hasOpened');
    if (!hasOpened) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        localStorage.setItem('hasOpened', 'true');
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div
      style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
    >
      {isOpen ? (
        <Card className="w-full max-w-md rounded-lg bg-white p-4 shadow-lg dark:bg-gray-800 dark:text-white sm:max-w-xs md:max-w-sm">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-lg font-semibold">Consulta Aquí 👇🏼</h2>
            <Button variant="ghost" size="sm" onClick={() => setIsOpen(false)}>
              ✖️
            </Button>
          </div>
          <FAQChat />
        </Card>
      ) : (
        <Button
          variant="outline"
          className="rounded-full p-4 shadow-lg dark:bg-gray-800 dark:text-white"
          onClick={() => setIsOpen(true)}
        >
          💬
        </Button>
      )}
    </div>
  );
}

function FAQChat() {
  const [chat, setChat] = useState<{ type: string; content: any }[]>([]);
  const [typing, setTyping] = useState(false);
  const chatEndRef = useRef<HTMLDivElement>(null);

  const faqs = [
    {
      question: '¿Quiénes somos en Mogos Group?',
      answer: `Mogos Group es un servicio puerta a puerta que conecta China con Venezuela. Hacemos que el proceso de envío sea fácil y eficiente, entregando tus productos directamente en la puerta de tu casa.`,
    },
    {
      question: '¿Qué servicios ofrecemos en Mogos Group?',
      answer: `Ofrecemos soluciones integrales de envío puerta a puerta desde China a Venezuela. Ya sea por vía marítima o aérea, contamos con una amplia red de agentes de carga y te acompañamos durante todo el proceso, garantizando que tus productos lleguen de manera segura y sin complicaciones. En Mogos Group, hacemos realidad nuestro compromiso: "China a la puerta de tu casa".`,
    },
    {
      question: '¿Cómo puedo hablar con el equipo de ventas?',
      answer: `Para contactar a nuestro equipo de ventas y obtener más información, puedes escribirnos directamente a través de WhatsApp haciendo clic en el siguiente enlace: <a href="https://api.whatsapp.com/send?phone=584241856592&text=Hola%20Mogos%20Group%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios" target="_blank" class="text-blue-500 underline">Hablar con nuestro equipo de ventas</a>.`,
    },
    {
      question: '¿Cómo etiqueto y preparo los envíos?',
      answer: `Para etiquetar y preparar tus envíos, sigue estos pasos:
      1. Regístrate en nuestra plataforma en <a href="/sign-up" target="_blank" class="text-blue-500 underline">/sign-up</a>.
      2. Busca la dirección de envío en <a href="/app/address" target="_blank" class="text-blue-500 underline">app/address</a>.
      3. Realiza un envío en <a href="/app/shipment" target="_blank" class="text-blue-500 underline">/app/shipment</a> adjuntando imágenes, el packing list y cualquier otra documentación necesaria.`,
    },
  ];

  const simulateTyping = (answer: string) => {
    let currentText = '';
    const interval = setInterval(() => {
      if (currentText.length < answer.length) {
        currentText += answer[currentText.length];
        setChat((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.type === 'answer') {
            return [
              ...prev.slice(0, -1),
              { type: 'answer', content: currentText },
            ];
          }
          return [...prev, { type: 'answer', content: currentText }];
        });
      } else {
        clearInterval(interval);
      }

      // Scroll to the bottom after each character is added
      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 30);
  };

  const handleQuestionClick = (faq: { question: any; answer: any }) => {
    setChat((prev) => [...prev, { type: 'question', content: faq.question }]);
    setTyping(true);

    setTimeout(() => {
      setTyping(false);
      simulateTyping(faq.answer);
    }, 1000); // Simulación de retraso de respuesta
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat, typing]);

  return (
    <div className="space-y-4">
      <div className="mb-4 flex flex-wrap gap-2">
        {faqs.map((faq, index) => (
          <Badge
            key={index}
            className="cursor-pointer"
            onClick={() => handleQuestionClick(faq)}
          >
            {faq.question}
          </Badge>
        ))}
      </div>

      <div className="max-h-72 space-y-2 overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-900 dark:text-white cursor-not-allowed">
        {chat.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.type === 'question' ? 'justify-end' : 'justify-start'
            }`}
          >
            {message.type === 'answer' ? (
              <div
                className={`inline-block rounded-lg p-2 text-sm ${'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}
                style={{
                  maxWidth: '85%',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
                dangerouslySetInnerHTML={{ __html: message.content }} // Solo se usa cuando es una respuesta con HTML
              />
            ) : (
              <div
                className={`inline-block rounded-lg p-2 text-sm ${'bg-primary-100 text-primary-900 dark:bg-primary-800 dark:text-primary-100'}`}
                style={{
                  maxWidth: '85%',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {message.content}{' '}
                {/* Se renderiza como texto plano para preguntas */}
              </div>
            )}
          </div>
        ))}

        {typing && (
          <div className="text-left">
            <div className="rounded-lg bg-gray-200 p-2 text-sm dark:bg-gray-700 dark:text-gray-300">
              ...
            </div>
          </div>
        )}

        {/* This invisible div is for auto-scrolling to the end */}
        <div className="cursor-not-allowed" ref={chatEndRef} />
      </div>
    </div>
  );
}
