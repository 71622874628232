import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../lib/utils';

const badgeVariants = cva(
  'inline-flex items-center border rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 itens-center justify-center w-fit',
  {
    variants: {
      variant: {
        default:
          'bg-none hover:bg-primary-50 border border-primary-600 text-primary-800',
        engineering:
          'bg-engineering-50 hover:bg-engineering-100 border-transparent text-engineering-700',
        faces:
          'bg-faces-50 hover:bg-faces-100 border-transparent text-faces-700',
        jyp: ' bg-jyp-50  hover:bg-primary-100 border-transparent text-jyp-700',
        personal: 'bg-blue-100 border-transparent text-blue-800',
        inProgress: ' bg-blue-100  border-transparent text-blue-800',
        danger: 'bg-red-100 border-transparent text-red-800',
        warning: 'bg-yellow-100 border-transparent text-yellow-800',
        success: 'bg-green-100 border-transparent text-green-800',
        humanities:
          'bg-humanities-50 hover:bg-humanities-100 border-transparent text-humanities-700',
        air: 'bg-green-100 border-transparent text-green-800',
        sea: 'bg-blue-100 border-transparent text-blue-800',
        land: ' bg-yellow-100  border-transparent text-yellow-800',
        sciences:
          'bg-sciences-50 hover:bg-sciences-100 border-transparent text-sciences-700',
        secondary:
          'bg-secondary hover:bg-secondary/80 border-transparent text-secondary-foreground',
        destructive:
          'bg-destructive hover:bg-destructive/80 border-transparent text-destructive-foreground',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
